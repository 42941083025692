<template>
  <div class="blog flex">
    <Header
      @delete="toggleConfirm('delete')"
      :details="{
        title: 'News',
        back: { name: 'news' },
        actions: {
          delete: true,
        },
      }"
    />

    <div class="col-8 left-side">
      <header class="lang d-flex justify-content-end">
        <Select class="lang-select" v-model="currentLanguage" :options="$store.state.global.availableLanguages" />
      </header>
      <template v-for="(lang, index) in $store.state.global.availableLanguages">
        <template v-if="lang.value === currentLanguage">
          <div class="input-box title" :key="'title-' + index">
            <input type="text" :placeholder="$t('overall.add_title')" v-model="translations[index].title" />
          </div>
          <div class="input-box" :key="'editor-' + index">
            <Editor v-model="translations[index].description" />
          </div>
        </template>
      </template>
      <div class="actions-bar">
        <template v-if="!loading">
          <button class="cancel">{{ $t("overall.cancel") }}</button>
          <button class="cta save fixed-button" @click="save">
            <span class="txt">{{ $t("overall.save") }}</span>
          </button>
        </template>
        <span class="loader" v-else></span>
      </div>
    </div>
    <div style="margin-top: 0" class="col-4 right-side">
      <div class="box-container">
        <div class="box-thumbnail">
          <label for="thumbnail" class="add-thumbnail change-image" v-if="!article.image && !tempImage">
            <span class="d-flex align-items-center justify-content-center"
              ><em><thumbnailSVG />{{ $t("properties.library.add_picture") }}</em></span
            >
          </label>
          <label for="thumbnail" v-else>
            <img :src="tempImage ? tempImage : article.image" alt="Thumbnail" />
          </label>
          <input class="hide" ref="image" type="file" @change="previewFiles" id="thumbnail" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import ArticleEditGeneric from "@/mixins/articleEditGeneric";

export default Vue.extend({
  name: "EditArticle",
  mixins: [ArticleEditGeneric],
  data() {
    return {
      api: "api/news",
      routeList: "news",
    };
  },
  created() {
    this.fetch();
  },
});
</script>
